<template>
    <fragment>
        <div class='lk__asset-wrapper'>

            <!-- Балансы -->
            <div class='lk__asset'>
                <img class='me-2' :src="'/static/img/ico/ico-' + crypto.title.toLowerCase() + '--color.png'" width='32px' />
                <div  class="lk__asset-block">
                    <app-spinner v-if="user_load == true" :load_item="user_load" :load_page="false"></app-spinner>
                    <span>{{parseFloat(balanses_crypto_id_value).toFixed(crypto.count_after_point)}} {{crypto.short_title}}</span>
                    <span style="color: #3EA075; font-size: 16px;">≈${{crypto.id == 1 ? parseFloat(balanses_crypto_id_value).toFixed(crypto.count_after_point) : (parseFloat(balanses_crypto_id_value) * cryptoCourse).toFixed(2)}}</span>
                </div>
            </div>

            <!-- Кнопка операций -->
            <button class="lk_operations ms-auto" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+crypto.id" aria-expanded="false" :aria-controls="'collapse-'+crypto.id">
                <img width="30" height="31" src="/static/img/operation-arrow.svg" alt="arrow">
                <span>Операции</span>
            </button>


            <buy-crypto v-if="crypto.short_title == 'USDT' || crypto.short_title == 'BTC'" v-show='isBuyVisible' @new_card="newCard" @buy='buyTether' @buy_cash="buyCash" @close='closeBuy' @new_buy="newBuy" :for_buy="for_buy.crypto_id == crypto.id ? for_buy : false" :user="user" :cards="cards" :courses="courses" :crypto="crypto" :courses_list="courses_list" :bank_list="bank_list" :currencies="currencies"/>

            <sale-crypto @showVerification="showVerification" v-if="crypto.short_title == 'USDT' || crypto.short_title == 'BTC'" v-show='isSaleVisible' @new_card="newCard" @sale="saleTether"  @sale_cash="saleCash" @close='closeSale' :user="user" :cards="cards" :balance="balanses_crypto_id_value" :courses="courses" :crypto="crypto" :courses_list="courses_list" :bank_list="bank_list" :currencies="currencies"/>

            <conclusion-crypto @showVerification="showVerification" v-show='isConcVisible' @new_wallet="newWallet" @concl="conclTether" :user="user" @close='closeConclusion' :wallets="wallets" :balance="balanses_crypto_id_value" :courses="courses" :crypto="crypto"/>

            <deposit-crypto v-show='isDepositVisible' @new_deposit="newDeposit" @deposit="deposit" @close='closeDeposit' :for_deposit="for_deposit.crypto_id == crypto.id ? for_deposit : false" :balance="balanses_crypto_id_value" :courses="courses" :crypto="crypto"/>

            <cash-crypto @showVerification="showVerification" v-if="crypto.short_title == 'USDT'" v-show='isCashVisible' @cash="cashTether" @close='closeCash' :cash_buy="cashBuy" :cash_sale="cashSale" :user="user" :balance="balanses_crypto_id_value" :courses="courses" :courses_list="courses_list" :crypto="crypto" :currenciess="currencies"/>
        </div>


   <div class='collapse' :id="'collapse-'+crypto.id">
            <div class="lk__buttons-block">
                <!-- Купить -->
                <button v-if="crypto.short_title == 'USDT' || crypto.short_title == 'BTC'" @click='showBuy'>
                    <img width="16" height="16" src="/static/img/crypto-elements/buy-crypto.svg" alt="buy">
                    <span>Купить</span>
                </button>
                <!-- Продать -->
                <button v-if="crypto.short_title == 'USDT' || crypto.short_title == 'BTC'" @click='showSale'>
                    <img width="16" height="16" src="/static/img/crypto-elements/sold-crypto.svg" alt="sold">
                    <span>Продать</span>
                </button>
                <!-- Депозит -->
                <button @click='showDeposit'>
                    <img width="16" height="16" src="/static/img/crypto-elements/deposit-crypto.svg" alt="deposit">
                    <span>Депозит</span>
                </button>
                <!-- Вывод -->
                <button @click="showConclusion">
                    <img width="16" height="16" src="/static/img/crypto-elements/withdrawal-crypto.svg" alt="withdrawal">
                    <span>Вывод</span>
                </button>
                <!-- Наличные -->
                <button v-if="crypto.short_title == 'USDT'" @click="showCash">
                    <img width="16" height="16" src="/static/img/crypto-elements/cashe-crypto.svg" alt="cashe">
                    <span>Наличные</span>
                </button>
            </div>

        </div>
    </fragment>
</template>
<script>
import BuyCrypto from './BuyCrypto.vue';
import SaleCrypto from './SaleCrypto.vue';
import ConclusionCrypto from "./ConclusionCrypto.vue";
import DepositCrypto from "./DepositCrypto.vue";
import CashCrypto from "./CashCrypto.vue";
import AppSpinner from "../General/AppSpinner.vue";
export default {
    props:['courses', 'crypto', 'balances', 'cards', 'wallets', 'user_load', 'user', 'courses_list', 'for_buy', 'for_deposit', "bank_list", "currencies"],
    components: {
        CashCrypto,
        BuyCrypto,
        SaleCrypto,
        ConclusionCrypto,
        DepositCrypto,
        AppSpinner,
    },
    data() {
        return {
            isBuyVisible: false,
            isSaleVisible: false,
            isConcVisible: false,
            isDepositVisible: false,
            isOperVisible: false,
            isCashVisible: false,
            cashBuy: false,
            cashSale: false,
            
        }
    },
    computed: {
        cryptoCourse: function () {
            let rate = 0
            Object.values(this.courses_list['Крипта']).forEach((course) => {
                if(course.cryptocurrency_id_to == this.crypto.id){
                    rate = course.rate;
                }
            });
            return rate;
        },
        balanses_crypto_id_value() {

            return this.balances.find(balance => balance.crypto_id == this.crypto.id)?.value ?? 0;
        },
    },
    methods: {
        showVerification(){
            this.$emit('showVerification');
        },
        showOperations(){
            if(this.isOperVisible == true) {
                this.isOperVisible = false;
            } else {
                this.isOperVisible = true;
            }
        },
        newCard(){
            this.$emit('new_card');
        },
        newWallet(){
            this.$emit('new_wallet');
        },
        buyTether(data) {
            this.$emit('buy', data);
        },
        newBuy() {
            this.$emit('new_buy');
        },
        newDeposit() {
            this.$emit('new_deposit');
        },
        saleTether(data) {
            this.$emit('sale', data);
        },
        conclTether(data) {
            this.$emit('concl', data);
        },
        deposit() {
            this.$emit('deposit');
        },
        cashTether() {
            this.$emit('cash');
        },
        buyCash() {
            this.isCashVisible = true;
            this.cashBuy = true;
            this.cashSale = false;
        },
        saleCash() {
            this.isCashVisible = true;
            this.cashSale = true;
            this.cashBuy = false;
        },
        showBuy() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.short_title.toLowerCase() + '_buy');
            }
            this.isBuyVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeBuy() {
            this.isBuyVisible = false;
            document.body.style.overflow = 'auto';
        },
        showSale() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.short_title.toLowerCase() + '_sell');
            }
            this.isSaleVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeSale() {
            this.isSaleVisible = false;
            document.body.style.overflow = 'auto';
        },
        showConclusion() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.short_title.toLowerCase() + '_withdrawal');
            }
            this.isConcVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeConclusion() {
            this.isConcVisible = false;
            document.body.style.overflow = 'auto';
        },
        showDeposit() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.short_title.toLowerCase() + '_deposit');
            }
            this.isDepositVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeDeposit() {
            this.isDepositVisible = false;
            document.body.style.overflow = 'auto';
        },
        showCash() {
            if(this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.short_title.toLowerCase() + '_cash');
            }
            this.isCashVisible = true;
            this.cashBuy = false;
            document.body.style.overflow = 'hidden';
        },
        closeCash() {
            this.isCashVisible = false;
            this.cashBuy = false;
            this.cashSale = false;
            document.body.style.overflow = 'auto';

        },
        listenChange(data) {
            this.amount = data;
            this.$emit('buy1');
        }
    },
}
</script>
