<template>
  <transition name="modal-fade">
    <div class="modal fade new_custom_modal" style="display: block; opacity: 1; background: rgba(29, 28, 43, 0.9)"
      id="addCard" aria-hidden="true" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content w-500">
          <div class="modal-header">
            <h5 class="modal-title">Добавить карту</h5>
            <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close"
              @click="close"></button>
          </div>
          <div class="modal-body">
            <div @click="changeCardTypeShow" class="row pb-2 cards-header cursor-pointer">
              <div class="col-6 py-2 text-center" :class="type == 'Карта' ? 'active' : ''">
                Карта
              </div>
              <div class="col-6 py-2 text-center" :class="type == 'СБП' ? 'active' : ''">
                СБП
              </div>
            </div>
            <div v-if="type == 'Карта'">
              <select class="o-input mb-2" v-model="country" name="card_bank">
                <option disabled="" value="">Выберите страну...</option>
                <option value="ru">🇷🇺 Россия</option>
                <option value="kz">🇰🇿 Казахстан</option>
                <option value="uz">🇺🇿 Узбекистан</option>
              </select>
              <select class="o-input mb-2" v-model="bank" name="card_bank">
                <option disabled="" value="">Выберите банк...</option>
                <option v-show="bank.name.toLowerCase() != 'СБП на сбер'.toLowerCase()" v-for="bank in actualBankList" v-bind:value="bank">
                  {{ bank.name }}
                </option>
              </select>
              <input v-if="bank != ''" class="o-input" type="text" name="number_card" v-model="number" id="card_field"
                v-mask="mask" placeholder="Номер Карты" />
              <div class="error-block-text" v-if="error_text">
                <p>{{ error_text }}</p>
              </div>
            </div>
            <div v-else>
              <select class="o-input mb-2" v-model="sbpBank" name="card_bank">
                <option disabled="" value="">Выберите банк...</option>
                <option v-for="bank in SBPBankList" v-bind:value="bank">
                  {{ bank.title }}
                </option>
              </select>
              <input v-if="sbpBank != ''" class="o-input" type="text" name="number_card" v-model="number" id="card_field"
                v-mask="mask" placeholder="Номер Телефона" />
              <div class="error-block-text" v-if="error_text">
                <p>{{ error_text }}</p>
              </div>
            </div>
            <button class="button button--full-width mt-2 mb-3" @click="add">
              Добавить карту
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Card from "./Card.vue";
import { value } from "lodash/seq";

export default {
  props: ['bank_list'],
  components: {
    Card,
  },
  created() {
    console.log("Компонент карт запущен");
    // this.getBankList();
    this.getSPBBankList();
  },
  computed: {
    mask: function () {
      if (this.bank != "") {
        if (this.bank.name == "Юмани") {
          return "###################";
        } else if (
          this.bank.name.toLowerCase() == "СБП на сбер".toLowerCase() ||
          this.bank.name == "Qiwi" ||
          this.type == "СБП"
        ) {
          return "+#(###)###-##-##";
        } else {
          return "#### #### #### ####";
        }
      } else {
        if (this.type == "СБП") {
          return "+#(###)###-##-##";
        } else {
          return "#### #### #### ####";
        }
      }
    },
    actualBankList : function () {
    if(this.country == 'kz') {
        return this.bank_list['kz']
    } else {
        if(this.country == 'uz') {
          return this.bank_list['uz']
        } else {
          return this.bank_list['ru']
        }
    }
    },
    sbp: function () {
      let sbp_bank = this.actualBankList.find((bank) => bank.name.toLowerCase() === "СБП на сбер".toLowerCase());
      return sbp_bank;
    },
  },
  data() {
    return {
      bank: "",
      sbpBank: "",
      number: "",
      country: "ru",
      bankList: [],
      SBPBankList: [],
      error_text: "",
      type: "Карта",
      click: false,
    };
  },

  methods: {
    value,
    close() {
      this.$emit("close");
    },
    add() {
      // Проверка на выбор типа карты
      if (this.type == "Карта") {
        if (!this.bank || !this.bank.id) {
          // Проверяем, что банк выбран и у него есть id
          this.error_text = "Выберите банк";
          return;
        }
      } else if (this.type == "СБП") {
        if (!this.sbpBank || !this.sbpBank.id) {
          // Проверяем, что банк СБП выбран и у него есть id
          this.error_text = "Выберите банк";
          return;
        }
      }

      // Проверка номера карты
      if (this.number == "") {
        this.error_text = "Номер карты не должен быть пустым";
        return;
      }

      // Проверка на повторное нажатие
      if (this.click) {
        return;
      }

      this.click = true;
      this.error_text = "";

      // Формирование данных для отправки
      let data = {
        bank_id: this.bank.id,
        card_number: this.number,
        country: this.country,
        user_id: this.$user_ID.value,
        token: sessionStorage.getItem('user_token'),
        sbp_bank_id: this.sbpBank.id,
      };

      console.log('bankData', data);

      // Отправка данных на сервер
      axios
        .post(this.$api_address + "/cards", data)
        .then((response) => {
          if (response.data.error) {
            this.error_text = response.data.error;
            this.click = false;
          } else {
            if (this.$metrika) {
              this.$metrika.reachGoal("adding_card_success");
            }
            let choisedBank = response.data.data;
      
      // Получить имя банка по его id и добавить в объект choisedBank
      const bankId = choisedBank.bank_id;
      const bankName = this.getBankNameById(bankId);

      if (bankName) {
        choisedBank.name = bankName;
      } else {
        console.log(`Банк с id ${bankId} не найден`);
      }
            eventBus.$emit("add_card", choisedBank);
            console.log("Добавлена карта: ", response.data.data);
            this.close();
            this.click = false;
          }
        })
        .catch(function (error) {
          console.log("Ошибка добавления карты: ", error.message);
          this.error_text = "Что-то пошло не так";
        });
    },
    changeCardTypeShow() {
      if (this.type === "Карта") {
        this.type = "СБП";
        this.country = 'ru';
        this.bank = this.sbp; // Обнуляем выбранный банк
        this.sbpBank = ""; // Обнуляем выбранный банк СБП
        this.number = ""; // Обнуляем номер карты
      } else {
        this.type = "Карта";
        this.bank = ""; // Обнуляем выбранный банк
        this.sbpBank = ""; // Обнуляем выбранный банк СБП
        this.number = ""; // Обнуляем номер карты
      }
    },
    // getBankList() {
    //   this.bank = ""; // Обнуляем выбранный банк
    //   this.sbpBank = ""; // Обнуляем выбранный банк СБП
    //   this.number = ""; // Обнуляем номер карты
    //   axios
    //     .get(this.$api_address + "/banks", {
    //       params: {
    //         country: this.country,
    //       },
    //     })
    //     .then((response) => {
    //       this.bankList = response.data;
    //       console.log("bankList: ", this.bankList);
    //     })
    //     .catch((error) => {
    //       console.error("Ошибка при получении данных: ", error);
    //     });
    // },
    getSPBBankList() {
      this.bank = ""; // Обнуляем выбранный банк
      this.sbpBank = ""; // Обнуляем выбранный банк СБП
      this.number = ""; // Обнуляем номер карты
      axios
        .get(this.$api_address + "/sbp_banks")
        .then((response) => (this.SBPBankList = response.data.data));
    },
    getBankNameById(id) {
    const bank = this.bankList.find(bank => bank.id === id);
    return bank ? bank.name : null;
    },
  },
};
</script>
