<template xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='cacheCryptoModal' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' aria-hidden='true' tabindex='-1'>
            <div class='modal-dialog modal-lg modal-dialog-centered'>
                <div class='modal-content no-transition'>
                        <div class='modal-header flex-wrap'>
                            <h5 v-if="step < 2" class='modal-title'>Наличные</h5>
                            <h5 v-if="step > 1 && step < 4 && type == 'Продажа'" class='modal-title'>Продать за наличные</h5>
                            <h5 v-if="step > 1 && step < 4 && type == 'Покупка'" class='modal-title'>Купить за наличные</h5>
                            <h5 v-if="step > 3 && type == 'Продажа'" class='modal-title'>Код выдачи</h5>
                            <h5 v-if="step > 3 && type == 'Покупка'" class='modal-title success_title'>Вы успешно оформили заявку</h5>
                            <div>
                                <button v-if="step == 1 || (step == 2 && cash_buy == true) || step == 4"
                                    class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' @click='close'
                                    aria-label='Close'></button>
                                <button v-else class='arrow-button' type='button' @click='prev'>
                                    <img src="/img/ico/white-arrow.svg" alt="стрелка влево" class="me-1">
                                    <span>Назад</span>
                                </button>
                            </div>
                            <div style="position: absolute; right:-50px; top:40px;">
                                <div style="display:flex; flex-direction: column">
                                    <span :class="step > 0 ? 'cash_steps_active' : 'cash_steps'">1</span>
                                    <span :class="step > 1 ? 'cash_steps_active' : 'cash_steps'">2</span>
                                    <span :class="step > 2 ? 'cash_steps_active' : 'cash_steps'">3</span>
                                    <span :class="step > 3 ? 'cash_steps_active' : 'cash_steps'">4</span>
                                </div>
                            </div>
                        </div>
                        <div class='modal-body' v-if='step === 1'>
                            <div class="d-flex justify-content-between align-items-center">
                                <span class='me-3 u-font-size-16'>Выберите тип операции с наличными</span>
                            </div>
                            <div style='text-align: left; min-height: 30px;' v-if="errorText">
                                <span class='me-3' style='font-size: 16px; color: red'>{{ errorText }}</span>
                            </div>
                            <div style="margin-top: 50px;">
                                <div
                                    style="display:flex; flex-direction:row; flex-wrap: wrap; justify-content: space-between; align-items: center">
                                    <div
                                        style="display:flex; width:40%; flex-direction:column; flex-wrap: wrap; justify-content: center; align-items: center">
                                        <img src="/img/fromCasheToCrypto.svg" alt="Купить за наличные">
                                        <button @click="buyCash" class='button button--full-width mb-3'
                                            style="margin-top: 20px;">Купить за наличные</button>
                                    </div>
                                    <div
                                        style="display:flex; width:40%; flex-direction:column; flex-wrap: wrap; justify-content: center; align-items: center">
                                        <img src="/img/fromCryptoToCache.svg" alt="Продать за наличные">
                                        <button @click="sellCash" class='button button--full-width mb-3'
                                            style="margin-top: 20px;">Продать за наличные</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='modal-body' v-else-if='step == 2'>
                            <div class="col-12 mb-2"><span>В каком городе хотите получить наличные?</span></div>
                            <div class="row mb-2">
                                <div class="col-4">
                                    <label @click="newCity" class='control control-radio'>
                                        Тверь
                                        <input :id="'c1'" type='radio' v-model='city' :value="'Тверь'" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                                <div class="col-4">
                                    <label @click="newCity" class='control control-radio'>
                                        Москва
                                        <input :id="'c2'" v-model='city' :value="'Москва'" type="radio" />
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 mb-2">
                                    <span>Способ получения</span>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div v-if="city != 'Ташкент'" class="col-4">
                                            <label class='control control-radio'>
                                                В офисе
                                                <input :id="'d1'" type='radio' v-model='delivery' :value="'В офисе'" />
                                                <div class='control_indicator'></div>
                                            </label>
                                        </div>
                                        <div class="col-4">
                                            <label class='control control-radio'>
                                                Доставка
                                                <input :id="'d2'" v-model='delivery' :value="'Доставка курьером'"
                                                    type="radio" />
                                                <div class='control_indicator'></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="delivery != 'В офисе'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span v-if="delivery == 'В офисе'">Адрес офиса</span>
                                    <span v-else>Адрес доставки</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <textarea style="width: 100%; border-radius: 5px; margin-top: 0px; cursor: text "
                                        class="inputAmount" v-model="address"></textarea>
                                </div>
                            </div>
                            <div class="error-block-text" v-show="delivery == 'В офисе'"><p>В данный момент, работает только доставка</p></div>
                            <div v-if="delivery != 'В офисе'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Выберите дату</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input required class="inputAmount"  type="date" id="date" @change="validate" :min="today" style="width: 100%; border-radius: 5px; margin-top: 0px; cursor: text " v-model='date'>
                                    <div class="error-block-text" v-show="errorData != ''"><p>{{errorData}}</p></div>
                                </div>
                            </div>
                            <div v-if="delivery != 'В офисе' && date != ''" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Забронировать время</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <select class="inputAmount" style="width: 100%; border-radius: 5px; margin-top: 0px;" v-model='time'>
                                        <option disabled='' selected='' value="">Выберите время</option>
                                        <option v-for="(active, time) in officeTime" :disabled="active == 1 ? false : true">{{time}}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="delivery != 'В офисе'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Контактные данные</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input class="inputAmount" placeholder="Никнейм телеграмма или телефон" style="width: 100%; border-radius: 5px; margin-top: 0px;" v-model='contact'>
                                </div>
                            </div>
                            <span style="margin-top: 30px; color: rgb(172, 175, 255);" v-if="type == 'Продажа' && user.status.id != 3">Пройдите <span @click="showVerification" class="text-light cursor-pointer">верификацию</span> для совершения продажи за наличные</span>
                            <button v-else @click="next" :disabled="address == '' || errorData != ''|| date == '' || contact == '' || time == '' || delivery == 'В офисе'" style="margin-top: 30px; width: 100%" class='button button--full-width'>Далее</button>
                        </div>
                        <div class='modal-body' v-else-if='step == 3'>
                            <div class="row mb-2">
                                <div class="col-6">
                                    <span>Отдаёте сумму в</span>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div v-for="cur in currencies" class="col-4">
                                            <label class='control control-radio' style="margin-right: 10px;">
                                                {{ cur }}
                                                <input @change="upd('usdt')" :id="cur" type='radio' v-model='currency'
                                                    :value="cur" />
                                                <div class='control_indicator'></div>
                                            </label>
                                        </div>
                                    </div>
                                    <p style="color: #3EA075; font-size:14px;">Обратите внимание - можно создать 1 заявку
                                        раз в 30 минут, в случае подозрительной активности аккаунт будет заблокирован.</p>
                                </div>
                            </div>
                            <div v-if="type == 'Продажа'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Сумма получения</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input @keyup="upd('cur')" class='inputAmount'
                                        style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                        v-model='form.amount' min="500" max="200000" name='address_wallet'
                                        autocomplete='off' placeholder='Кол-во валюты' />
                                    <p style="color: #3EA075; font-size:14px;">Доступный баланс: {{ balance }} USDT</p>
                                    <div class="error-block-text" v-show="errorInput != ''">
                                        <p>{{ errorInput }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="type == 'Продажа'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Размер списания</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input class='inputAmount' @keyup="upd('usdt')"
                                        style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                        v-model='form.amountUSDT' name='address_wallet' autocomplete='off'
                                        placeholder='Кол-во валюты' />
                                    <p style="color: #3EA075; font-size:14px;">В том числе комиссия 1%</p>
                                </div>
                            </div>
                            <div v-if="type == 'Покупка'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Сумма USDT</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input class='inputAmount' @keyup="upd('usdt')"
                                        style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                        v-model='form.amountUSDT' name='address_wallet' autocomplete='off'
                                        placeholder='Кол-во валюты' />
                                </div>
                            </div>
                            <div v-if="type == 'Покупка'" class="row mb-2">
                                <div class="col-12 col-sm-6">
                                    <span>Отдаете наличными</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input @keyup="upd('cur')" class='inputAmount'
                                        style="width: 100%; border-radius: 5px; margin-top: 0px;" type='number'
                                        v-model='form.amount' min="500" max="200000" name='address_wallet'
                                        autocomplete='off' placeholder='Кол-во валюты' />
                                    <p style="color: #3EA075; font-size:14px;">В том числе комиссия 1%</p>
                                    <div class="error-block-text" v-show="errorInput != ''">
                                        <p>{{ errorInput }}</p>
                                    </div>
                                </div>
                            </div>
                            <button @click="next" :disabled="form.amount == null || form.amount == 0 || errorInput !== ''"
                                style="margin-top: 30px; width: 100%" class='button button--full-width'>Далее</button>
                        </div>
                        <div class='modal-body' v-if="step > 3 && type == 'Продажа'">
                            <div
                                style="display:flex; width:100%; flex-direction:column; justify-content: space-between; align-items: flex-start; min-height: 400px;">
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <p v-if="currency == 'USD'" style="color: #3EA075; font-size:14px;">Обратите внимание -
                                        можно создать 1 заявку раз в 30 минут, в случае подозрительной активности аккаунт
                                        будет заблокирован.</p>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Ожидайте курьера по адресу:</span>
                                    <span style="font-size: 16px">{{ address }}</span>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Дата получения</span>
                                    <span style="font-size: 16px">{{ moment(date).format("DD-MM-YYYY") }}</span>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Время получения</span>
                                    <span style="font-size: 16px">{{ time }}</span>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Сумма наличных</span>
                                    <span style="font-size: 16px">{{ form.amount }} {{ currency }}</span>
                                </div>
                                <div class="w-100 p-4 text-center">
                                    <span style="font-size: 46px;">{{ code }}</span>
                                    <div class="d-block position-relative">
                                        <button class="cash-button" @click="copyCard">
                                            <img src="/img/ico/ico-copy.svg" style="margin-right: 5px;" width="12" height="15" alt="Скопировать">
                                            <span>Скопировать</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='modal-body' v-if="step > 3 && type == 'Покупка'">
                            <div
                                style="display:flex; width:100%; flex-direction:column; justify-content: space-between; align-items: flex-start; min-height: 400px;">
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <p style="color: #3EA075; font-size:14px;">Обратите внимание - можно создать 1 заявку
                                        раз в 30 минут, в случае подозрительной активности аккаунт будет заблокирован.</p>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Ожидайте курьера по адресу:</span>
                                    <span style="font-size: 16px">{{ address }}</span>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Дата получения</span>
                                    <span style="font-size: 16px">{{ moment(date).format("DD-MM-YYYY") }}</span>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Время получения</span>
                                    <span style="font-size: 16px">{{ time }}</span>
                                </div>
                                <div
                                    style="display:flex; width:100%; flex-direction:column; justify-content: center; align-items: flex-start">
                                    <span>Сумма {{ currency }}</span>
                                    <span style="font-size: 16px">{{ form.amount }} {{ currency }}</span>
                                </div>
                                <button @click="close" style="margin-top: 30px; width: 100%"
                                    class='button button--full-width'>
                                    Хорошо</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>

import IndexComponent from "../Index/IndexComponent.vue";
import HistoryItemComponent from "../HistoryItemComponent.vue";
import HistoryComponent from "../HistoryComponent.vue";
import moment from "moment";



export default {
    components: { IndexComponent },
    props: ['cards', 'courses', 'balance', 'user', 'cash_buy', 'cash_sale', 'courses_list', 'crypto', 'currenciess'],
    created() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

            this.today = yyyy + '-' + mm + '-' + dd;
        },
        data() {
            return {
                moment: moment,
                tipe: 'text',
                selectedDate: null,
                form : {
                    bank: 0,
                    amount: 0,
                    amountUSDT: 0,
                    file: '',
                    cardNumber: '',
                },
                type: '',
                step: 1,
                address:'',
                date: '',
                today: new Date(),
                time: '',
                currency: 'USD',
                logo: '',
                contact: '',
                city:'Тверь',
                delivery: 'Доставка курьером',
                ourCard: 'XXXX XXXX XXXX XXXX',
                title: 'cashTether',
                errorText: '',
                currencies: [],
                placeHolder: '',
                commission: 0.01,
                errorInput: '',
                errorData: '',
                code: '',
                past: 'Прошедшая дата',
                good: 'Дата указана верно',
                officeTime: {
                    '10:00-10:30': 1,
                    '10:30-11:00': 1,
                    '11:00-11:30': 1,
                    '11:30-12:00': 1,
                    '12:00-12:30': 1,
                    '12:30-13:00': 1,
                    '13:00-13:30': 1,
                    '13:30-14:00': 1,
                    '14:00-14:30': 1,
                    '14:30-15:00': 1,
                    '15:00-15:30': 1,
                    '15:30-16:00': 1,
                    '16:00-16:30': 1,
                    '16:30-17:00': 1,
                    '17:00-17:30': 1,
                    '17:30-18:00': 1,
                    '18:00-18:30': 1,
                    '18:30-19:00': 1,
                    '19:00-19:30': 1,
                    '19:30-20:00': 1,
                    '20:00-20:30': 1,
                    '20:30-21:00': 1,
                    '21:00-21:30': 1,
                    '21:30-22:00': 1,
                    '22:00-22:30': 1,
                    '22:30-23:00': 1,
                },
            }
        },
        watch: {
            cash_buy: {
                handler() {
                    if(this.cash_buy != false) {
                        this.buyCash();
                    }
                },
            },
            cash_sale: {
                handler() {
                    if(this.cash_sale != false) {
                        this.sellCash();
                    }
                },
            },
            date: {
                handler() {
                    const data = new Date(this.date);
                    const today = new Date(this.today);
                    console.log(data);
                    console.log(today);
                    if(data.getTime() == today.getTime()) {
                        console.log(1);
                        Object.entries(this.officeTime).forEach((num) => {
                            if(this.checkTime(num[0]) == true) {
                                this.officeTime[num[0]] = 1
                            } else {
                                this.officeTime[num[0]] = 0;
                            }
                        });
                    } else {
                        Object.entries(this.officeTime).forEach((num) => {
                            this.officeTime[num[0]] = 1
                        });
                    }
                }
            }
        },
        computed: {
            timeNow() {
                const today = new Date();
                let hours = today.getHours();
                let minutes = today.getMinutes();
                return hours + ':' + minutes;
            }
        },
        methods: {
            showVerification() {
                console.log('Передано');
                this.$emit('showVerification');
            },
            checkTime(val) {
                let time = this.timeNow.split(':');
                let diapasone = val.split('-');
                let value = diapasone[0].split(':');
                if(parseInt(time[0]) > parseInt(value[0])) {
                    return false;
                } else {
                    if(parseInt(time[0]) == parseInt(value[0]) && parseInt(time[1]) > parseInt(value[1])) {
                        return false;
                    } else {
                        return true;
                    }

                }
            },
            updBuy() {
                if(this.cash_buy) {
                    this.step = 2;
                    this.type = 'Покупка';
                }
            },
            copyCard() {
                navigator.clipboard.writeText(this.code);
            },

            currencyCourseBuy() {
                if (this.courses_list && this.courses_list['Покупка']) {
                    let course = this.courses_list['Покупка'].find(c => c.cryptocurrency_id_from === this.crypto.id 
                    && c.currency_id_to === this.currenciess.find(c => c.short_title === this.currency).id).rate;
                    console.log('course', course);
                    return parseFloat(course).toFixed(2);
                } else {
                    return 1;
                }
            },

            currencyCourseSale() {
                if (this.courses_list && this.courses_list['Продажа']) {
                    let course = this.courses_list['Продажа'].find(c => c.cryptocurrency_id_from === this.crypto.id 
                    && c.currency_id_to === this.currenciess.find(c => c.short_title === this.currency).id).rate;
                    console.log('course', course);
                    return parseFloat(course).toFixed(2);
                } else {
                    return 1;
                }
            },

            upd(key) {
                let course = {0:1,1:1};
                // if(this.currency == 'RUB') {
                //     course = this.courses_list;
                // }
                if(key == 'usdt' && this.type == 'Покупка'){
                    this.form.amount = (this.currencyCourseBuy() * (parseFloat(this.form.amountUSDT) + parseFloat(this.form.amountUSDT) * this.commission)).toFixed(2);
                    setTimeout(() => {
                        if(this.currency == 'RUB') {
                            this.form.amount = Math.round(this.form.amount / 100) * 100;
                        } else {
                            this.form.amount = Math.round(this.form.amount);
                        }
                    }, 2500);
                }
                if(key == 'cur' && this.type == 'Покупка') {
                    this.form.amountUSDT = ((parseFloat(this.form.amount) / (1 + this.commission)) / this.currencyCourseBuy()).toFixed(0);
                    setTimeout(() => {
                        if(this.currency == 'RUB') {
                            this.form.amount = Math.round(this.form.amount / 100) * 100;
                        } else {
                            this.form.amount = Math.round(this.form.amount);
                        }
                    }, 2500);
                }
                if(key == 'usdt' && this.type == 'Продажа'){
                    this.form.amount = ((parseFloat(this.form.amountUSDT) / (1 + this.commission)) * this.currencyCourseSale()).toFixed(0);
                    setTimeout(() => {
                        if(this.currency == 'RUB') {
                            this.form.amount = Math.round(this.form.amount / 100) * 100;
                        } else {
                            this.form.amount = Math.round(this.form.amount);
                        }
                    }, 2500);
                }
                if(key == 'cur' && this.type == 'Продажа'){
                    this.form.amountUSDT = ((parseFloat(this.form.amount) + parseFloat(this.form.amount) * this.commission) / this.currencyCourseSale()).toFixed(0);
                    if(this.currency === 'RUB') {
                        setTimeout(() => {
                            this.form.amount = Math.round(this.form.amount / 100) * 100;
                        }, 2500);
                    } else {
                        this.form.amount = Math.round(this.form.amount);
                    }
                }
                if(this.currency == 'USD') {
                    if(this.form.amountUSDT < 500 && this.city == 'Тверь') {
                        this.errorInput = 'Минимальная сумма покупки 500 USDT';
                        return;
                    } else {
                        this.errorInput = ''
                    }
                    if(this.form.amountUSDT < 5000 && this.city == 'Москва') {
                        this.errorInput = 'Минимальная сумма покупки 5000 USDT';
                        return;
                    } else {
                        this.errorInput = ''
                    }
                    if(this.form.amountUSDT > 200000) {
                        this.errorInput = 'Максимальная сумма покупки 200 000 USDT';
                        return;
                    } else {
                        this.errorInput = ''
                    }
                } else {
                    if(this.form.amount < 50000 ) {
                        this.errorInput = 'Минимальная сумма покупки 50 000 RUB';
                        return;
                    } else {
                        this.errorInput = ''
                    }
                    if(this.form.amount > 20000000) {
                        this.errorInput = 'Максимальная сумма покупки 20 000 000 RUB';
                        return;
                    } else {
                        this.errorInput = ''
                    }
                }

            if (parseFloat(this.form.amountUSDT) > parseFloat(this.balance) && this.type == 'Продажа') {
                this.errorInput = 'Недостаточно средств';
                return;
            }
        },
        validate() {
            const date = new Date(this.date);
            const today = new Date(this.today);
            if (date.getTime() < today.getTime()) {
                this.errorData = 'Дата не должна быть раньше чем сегодня';
                return false;
            } else {
                this.errorData = '';
                return true;
            }
        },
        next() {
            if (this.step == 2) {
                if (this.city == 'Тверь') {
                    this.currencies = {
                        0: 'USD',
                        1: 'RUB',
                    }
                }
                if (this.city == 'Москва') {
                    this.currencies = {
                        0: 'USD',
                        1: 'RUB',
                    }
                }
                if (this.city == 'Ташкент') {
                    this.currencies = {
                        0: 'USD',
                        1: 'RUB',
                    }
                }
            }
            if (this.step == 3) {
                let chrs = 'ABDEFGHKMNPQRSTWXZ123456789';
                var str = '';
                for (var i = 0; i < 9; i++) {
                    var pos = Math.floor(Math.random() * chrs.length);
                    str += chrs.substring(pos, pos + 1);
                }
                this.code = str;
                let course = { 0: 1, 1: 1 };
                if (this.currency == 'RUB') {
                    course = this.courses;
                }
                let data = {
                    amount: this.form.amountUSDT,
                    city: this.city,
                    address: this.address,
                    date: this.date,
                    time: this.time,
                    contact: this.contact,
                    // commission: this.commission,
                    delivery_type : this.delivery == 'Доставка курьером' ? 'Доставка' : 'Самовывоз',
                    currency_id : this.currency == 'USD' ? 4 : 1,
                    crypto_id : 1,
                    operation_type : this.type,
                    user_id: this.$user_ID.value,
                    token: sessionStorage.getItem('user_token'),
                    code: this.code,
                    // course: course
                }
                if (this.type == 'Продажа') {
                    data.code = this.code;
                }
                // console.log(data);
                let resp = '';
                axios.post(this.$api_address + '/cashes', data).then(response => {
                    console.log(response.data);
                    if (response.data.error) {
                        this.errorText = resp.error;
                        alert('недостаточно денежных средств');
                        return;
                    } else {
                        this.$emit('cash');
                    }
                });
            }
            this.step++;
        },
        prev() {
            this.step--;
        },
        newCity() {
            this.address = '';
        },
        close(event) {
            this.$emit('close');
            this.address = '';
            this.date = null;
            this.contact = null;
            this.time = '';
            this.step = 1;
            this.form.amount = null;
            this.form.amountUSDT = null;
        },
        buyCash() {
            this.step = 2;
            this.type = 'Покупка';
        },
        sellCash() {
            this.type = 'Продажа';
            this.step = 2;
        }
    },
}
</script>

<style scoped>
    .cash-button {
        position: absolute;
        bottom: 20px;
        right: 0;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color:  #ffffff;
        text-decoration: underline;
    }
    
    @media(max-width:375px) {
        .success_title {
            font-size: 16px;
        }
    }
</style>
