<template>
    <main>
        <img class="d-sm-none d-block o-bg-main--mobile" src="/static/img/index/bg-first-section--mobile.png" />
        <section  class="section section--main">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-12 col-sm-6 col-12 position-relative" style="z-index: 1">
                        <div class="main__title">
                            Мгновенный <br />
                            <span class="u-color-pall">обмен</span> <img src="/static/img/index/exchange.svg" /> криптовалюты
                        </div>
                        <div class="main__desc">Надежный круглосуточный сервис</div>
                        <div class="d-flex mt-4 justify-content-start" v-if="user">
                            <button class="button" @click="intoLk">В личный кабинет</button>
                        </div>
                        <div class="d-flex mt-4 justify-content-start gap-1 mb-5 mb-lg-0" v-else>
                            <button class="button" data-bs-toggle="modal" data-bs-target="#regModal">Регистрация</button>
                            <button v-if="!user" class="button d-flex d-lg-none" data-bs-toggle="modal" data-bs-target="#enterModal">Вход</button>
                        </div>
                    </div>
                    <div class="col-6 d-xl-block d-lg-none d-sm-block d-none">
                        <div class="main__img">
                            <img class="o-bg-main" src="/static/img/index/bg-first-section.png" />
                            <img class="mw-100 position-relative" src="/static/img/index/main-image.webp" style="z-index: 0;" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5 position-relative">
                    <div class="col-sm-4 col-12 text-sm-center text-start mb-sm-0 mb-4"><div class="o-brackets o-text-primary">Выплаты до 5 минут</div></div>
                    <div class="col-sm-4 col-12 text-sm-center text-start mb-sm-0 mb-4"><div class="o-brackets o-text-primary">Возможность наличного обмена</div></div>
                    <div class="col-sm-4 col-12 text-sm-center text-start mb-sm-0 mb-4"><div class="o-brackets o-text-primary">Карты любых банков</div></div>
                </div>
            </div>
        </section>
        <section  class="section section--cta">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="cta">
                            <div class="cta__title">
                                <span>Любые направления обмена</span>
                                <img src="/static/img/ico/ico-tether.svg" class="ms-4">
                                <img src="/static/img/ico/ico-bitcoin.svg" class="ms-2"></div>
                            <div class="o-text-primary">
                                К обмену доступны BTC, USDT, BNB, ETH, TON, LTC<br> А также наличные по всей географии присутствия нашего сервиса.
                            </div>
                            <img class="o-cta-img" src="/static/img/index/bg-base-currency.png" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section  class="section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="o-text-title mb-lg-4 mb-3 banks_title">
                            <h2>Быстрые перестановки денег в EC, Казахстане, Узбекистане<span>
                                <img src="/static/img/invoice/first/kz_flag.svg">
                                <img width="32" height="21" src="/static/img/invoice/first/eu_flag.jpg">
                                <img src="/static/img/invoice/first/uz_flag.svg">
                            </span></h2>
                            <ul>
                                <li><p><span>Мгновенные</span> выводы криптовалюты</p></li>
                                <li><p>Обработка заявок <span>от 30 секунд</span></p></li>
                                <li><p><span>Лучшие курсы</span> на покупку криптовалюты</p></li>
                                <li><p>Поддержка <span>24/7</span></p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container px-0">
                <div class="bank-item__list">

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img width="117px" src="/static/img/banks/logo-paypal.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img width="117px" src="/static/img/banks/logo-visa.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img width="117px" src="/static/img/banks/logo-mc.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/click.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/kaspi.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/centercredit.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/capital.png" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/jusan.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/uzum.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/paynet.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/halyk.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img src="/static/img/banks/forte.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img width="150" src="/static/img/banks/perfectmoney.svg" loading="lazy"/></div>
                    </div>

                    <div class="bank-item__wrapper">
                        <div class="bank-item"><img width="150" src="/static/img/banks/adv_cash.svg" loading="lazy"/></div>
                    </div>

                    <!-- <div class="bank-item__wrapper bank-item__wrapper--twice">
                        <div class="bank-item"><span class="bank-item__big-text">+17</span><span class="bank-item__text">способов оплаты</span></div>
                    </div> -->
                </div>
            </div>
        </section>
        <section  class="section section--options">
            <div class="container">
                <div class="row option-item__wrapper">
                    <div class="col-md-6 col-12 order-md-1 order-2">
                        <div class="option-item">
                            <div class="option-item__title">Наличный обмен</div>
                            <div class="option-item__desc">
                                Через наш сервис возможно обменять криптовалюту на наличные средства во многих городах мира
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-12 order-md-2 order-1">
                        <div class="option-item__img"><img src="/static/img/index/cash.png" loading="lazy"/></div>
                    </div>
                </div>
                <div class="row option-item__wrapper">
                    <div class="col-md-6 col-12">
                        <div class="option-item__img"><img src="/static/img/index/lk.png" loading="lazy"/></div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="option-item option-item--right-border">
                            <div class="option-item__title">Личный кабинет</div>
                            <div class="option-item__desc">
                                <p><span class="u-color-pall">Простой и понятный</span> интерфейс управления активами</p>
                                <p>Покупка, продажа, депозит, вывод без комиссий! А также, надежное хранение активов.</p>
                            </div>
                            <div class="option-item__btn">
                                <button v-if="user" class="button" @click="intoLk">Регистрация</button>
                                <button v-else class="button" data-bs-toggle="modal" data-bs-target="#regModal">Регистрация</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row option-item__wrapper">
                    <div class="col-md-6 col-12 order-md-1 order-2">
                        <div class="option-item"><div class="option-item__title">Реферальная программа</div>
                            <div class="option-item__desc">Делитесь ссылкой, приглашайте друзей и получайте вознаграждение за каждого зарегистрированного пользователя.</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 order-md-2 order-1">
                        <div class="option-item__img"><img src="/static/img/index/referal.png" loading="lazy"/></div>
                    </div>
                </div>
                <div class="row option-item__wrapper">
                    <div class="col-md-6 col-12">
                        <div class="option-item__img"><img src="/static/img/index/exchange.png" loading="lazy"/></div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="option-item option-item--right-border">
                            <div class="option-item__title">Мгновенная конвертация</div>
                            <div class="option-item__desc">
                                Мгновенная конвертация криптоактивов с <span class="u-color-pall">минимальной комиссией</span> <br />
                                <br />
                                Конвертируйте и выводите криптовалюту в 1 клик
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section  class="section pt-0 my-5">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="app-soon">
                            <div class="app-soon__container">
                                <div class="o-text-secondary mb-2 w-100">Приложение скоро! <img class="ms-lg-3" src="img/ico/ico-apple.svg" /> <img class="ms-lg-2" src="img/ico/ico-android.svg" /></div>
                                <div class="o-text-primary u-line-height-2 mb-lg-5 mb-4">
                                    Все функции криптообмена в мобильном телефоне. <br />
                                    Покупать, продавать и хранить криптовалюту — будет проще и удобнее!
                                </div>
                                <img class="d-block d-sm-none d-lg-block app-soon__img" src="img/app-soon.png" loading="lazy"/>
                                <div class="app-soon__buttons">
                                    <input class="me-sm-3 o-input mb-3 mb-sm-0" type="email" v-model="mail" placeholder="Email" /><button class="button button--full-width" @click="sentTg">Сообщите мне о запуске</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section  class="section section--info mb-5 pt-xl-5 pt-0 pb-xl-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-12 order-md-1 order-2"><div class="o-text-secondary mb-3">Доверяйте работу профессионалам!</div>
                        <div class="o-text-primary">
                            Мы гарантируем полноценную анонимность, сохранность Ваших активов и данных. Наш сервис разработан на основе многолетнего опыта командой высококлассных специалистов.<br> <br>
                            Вы можете быть уверены, что Ваши активы останутся с Вами. Круглосуточная поддержка поможет решить любой вопрос.<br> <br>
                            <span class="u-color-pall">Регистрируйтесь и начинайте совершать сделки с криптовалютами!</span></div>
                    </div>
                </div>
            </div>
        </section>
        <cash-component v-show='is_nal_show' @open_nal="open_nal" @close="close_nal"></cash-component>
    </main>
</template>
<script>

import CashComponent from "../Index/CashComponent.vue";
export default {
    props: ['user', 'is_nal_show', 'index'],
    components: {
        CashComponent,
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g,
            '$1 '),
    },
    data() {
        return {
            mail: '',
            botLink: 'https://api.telegram.org/bot6113248248:AAHyrwwD150lrxXkc301XdtwApXMR5IZOXw/sendMessage?chat_id=-866309720&text=',
        }
    },
    methods: {
        sentTg() {
            axios(this.botLink + 'Подписка на рассылку: ' + this.mail).then(alert('Почта успешно отправлена'),this.mail='');
        },
        login_user(){
            this.$emit('login_user');
        },
        intoLk() {
            this.$router.push('/account');
        },
        open_nal() {
            this.$emit('open_nal');
        },
        close_nal() {
            this.$emit('close_n');
        },
        //Удалить?
        // clickCallback (page)  {
        //     this.page = page
        //     this.$emit('new_page',page);
        //     console.log(page);
        // }
    }
}
</script>

<style scoped>
    .bank-item__wrapper .bank-item {
        border-radius: 5px;
    }

    .banks_title {
        max-width: 685px;
    }
    .banks_title ul {
        list-style-type: disc;
        color: #4AA485;
        list-style-position: inside
    }

    .banks_title h2 {
        font-family: Montserrat, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .banks_title h2 span img {
        height: 21px;
        margin-left: 21px

    }

    .banks_title ul p {
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: inline-block;
    }
    .banks_title ul p span {
        color: #4AA485;
    }

    @media(max-width:500px) {
        .banks_title ul p {
            font-size: 15px;
            margin-bottom: 0;
            text-wrap: wrap;
        }

        .banks_title h2 {
            font-size: 20px;
        }

        .banks_title ul li {
            text-wrap: nowrap;
        }

        .banks_title h2 span {
            display: flex;
            gap: 21px;
            margin-top: 5px;
        }

        .banks_title h2 span img {
            margin: 0;
        }
    }


</style>
