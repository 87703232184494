<template>
    <header class='header'>
        <div class='container'>

            <nav class="navbar navbar-dark navbar-expand-lg shadow-none">
                <div class="container-fluid p-0">
                    <div class="navbar-brand cursor-pointer" @click="$router.push('/')">
                        <img class='mw-100' src='/static/img/logo.svg' width="160" height="48" />
                    </div>
                    <button class="navbar-toggler shadow-none border-0 p-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item" v-if="user && user.length !== 0"><router-link class="nav-link" to="/account">Активы</router-link></li>
                            <li class="nav-item" v-if="user && user.length !== 0"><router-link class="nav-link" to="/pay-link">Оплата по ссылке</router-link></li>
                            <li class="nav-item"><a class="nav-link" target="_blank" href='https://t.me/bsb_money'>Поддержка</a> </li>
                        </ul>

                        <!-- Если авторизован -->
                        <div class='d-flex gap-4 justify-content-between justify-content-lg-start' v-if="user && user.length !== 0">
                            <div class='nav__lk order-1 cursor-pointer' @click="$router.push('/account')"><img src='/img/ico/ico-user.svg' />{{ user.nickname }}<br>{{ 'ID:' + this.$user_ID.value }}</div>
                            <button class='nav__lk d-lg-flex order-3 order-lg-2' @click="logout" type='submit'><img  src='/img/ico/ico-logout.svg' /> Выйти</button>
                            <a class='order-2 order-lg-3' href="https://t.me/bsb_money" target='_blank'><img src='/img/ico/ico-telegram.svg' /></a>
                        </div>

                        <!-- Если не авторизован -->
                        <div class="d-flex gap-4 justify-content-between justify-content-lg-start mt-3" v-else>
                            <button class="button button--small button--transparent" data-bs-toggle="modal" data-bs-target="#enterModal">Вход</button>
                            <button class="button button--small button--transparent" data-bs-toggle="modal" data-bs-target="#regModal">Регистрация</button>
                            <a class="" href="https://t.me/bsb_money" target="_blank"><img src="/img/ico/ico-telegram.svg" /></a>
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>
<script>
import AppSpinner from "./AppSpinner.vue";
export default {
    props: ['user'],
    components: {
        AppSpinner,
    },
    // mounted() {
    //     if (window.location.pathname == '/') {
    //         this.index = true;
    //     }
    //     if (window.location.hostname == 'noros.exchange') {
    //         this.prod = true;
    //     }
    //     const urlParams = new URLSearchParams(window.location.search);
    //     if (urlParams.get('reg') !== null) {
    //         this.isRegistrationVisible = true;
    //     }
    // },
    data: () => ({
        nickname: '',
        email: '',
        referal_code: '',
        password: '',
        mobileMenu: false,
        password_repeat: '',
        index: false,
        prod: false,
        errors: {
            'email': '',
            'nickname': '',
            'referal_code': '',
            'password': '',
            'password_repeat': '',
            'general': '',
        },
        isNalShow: false,
    }),
    methods: {
        switchMobileMenu() {
            if (this.mobileMenu) {
                this.mobileMenu = false;
                document.body.style.overflow = 'auto';
            } else {
                this.mobileMenu = true;
                document.body.style.overflow = 'hidden';
            }
        },
        close_nal() {
            this.isNalShow = false;
        },
        showNal(modal) {
            this.isNalShow = true;
        },
        logout() {

            this.$emit('logout');
        },
        login_user() {
            this.$emit('login_user');
        },
        // register() {
        //     let data = {
        //         nickname: this.nickname,
        //         email: this.email,
        //         referal_code: this.referal_code,
        //         password: this.password,
        //         password_repeat: this.password_repeat
        //     }
        //     axios.post('/register', data)
        //         .then(response => {
        //             console.log(response.data);
        //         })
        //         .catch(function (error) {
        //             this.errors.general = 'Что-то пошло не так';
        //         });
        //     //this.close();
        // },
    }
}
</script>

<style scoped>
    .router-link-active {
        color: #4AA485;
    }
</style>



