<template>
    <section class="courses-block">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg d-flex justify-content-between">
                    <div class="o-text-primary u-font-weight-600 mb-2">Курсы валют</div>
                    <span class="courses-line-last-child fw-300 fz-16">Обновлено: {{ moment(courses_BTC2USDT_updated_at).format("DD-MM-YYYY HH:mm")}}</span>
                </div>
            </div>
            <div class="row green_bg courses_wrap brs-5 m-0 align-items-center">
                <div class="col-12 col-lg-3 courses_wrap_item">
                    <span class="fz-16">1 BTC = {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate).toFixed()) }} USDT</span>
                </div>
                <div class="col-12 col-lg-3 courses_wrap_item">
                    <span class="fz-16">1 USDT =<!--{{ new Intl.NumberFormat('ru-RU').format(courses_USDT2rub_rate_0) }}-->3.97 PLN</span>
                </div>
                <div class="col-12 col-lg-3 courses_wrap_item">
                    <span class="fz-16">1 USDT = {{ new Intl.NumberFormat('ru-RU').format(USDT2EUR_buy) }} EUR</span>
                </div>
                <div class="col-12 col-lg text-end px-0">
                    <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCourses" aria-expanded="false" aria-controls="collapseCourses">
                        <span class="arrow"><img src="/static/img/ico/ico-accordeon.svg"></span>
                        <span>Подробнее</span>
                    </button>
                </div>
               
            </div>

        </div>
        <div class="collapse" id="collapseCourses" style="background:#212121">
            <div class="container">
                <div class="row green_border m-0 py-3">

                    <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">BTC <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> PLN</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate * courses_USDT2rub_rate_0).toFixed()) }} PLN</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate * courses_USDT2rub_rate_sale).toFixed()) }} PLN</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">BTC  <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> KZT</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate * courses_USDT2kzt_rate_0).toFixed()) }} KZT</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate * courses_USDT2kzt_rate_sale).toFixed()) }} KZT</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">BTC <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> UZS</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate * courses_USDT2uzs_rate_0).toFixed()) }} UZS</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(courses_BTC2USDT_rate * courses_USDT2uzs_rate_sale).toFixed()) }} UZS</span>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> PLN</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"><!--{{ new Intl.NumberFormat('ru-RU').format(courses_USDT2rub_rate_0) }}-->4.05 PLN</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"><!--{{ new Intl.NumberFormat('ru-RU').format(courses_USDT2rub_rate_sale) }}-->3.97 PLN</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> KZT</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(courses_USDT2kzt_rate_0) }} KZT</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(courses_USDT2kzt_rate_sale) }} KZT</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> UZS</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(courses_USDT2uzs_rate_0) }} UZS</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(courses_USDT2uzs_rate_sale) }} UZS</span>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> EUR</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(USDT2EUR_buy) }} EUR</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(USDT2EUR_sale) }} EUR</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</template>
<script>
import moment from "moment";
export default {
    props: ['courses'],
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g,
            '$1 '),
    },
    data() {
        return {
            moment: moment,
            openCourse: {
                'USDT': false,
                'BTC': false,
            },
        }
    },
    methods: {
        open(course) {
            if (this.openCourse[course] == true) {
                this.openCourse[course] = false;
            } else {
                this.openCourse[course] = true;
            }
        },
    },
    computed: {
        courses_BTC2USDT_rate() {
            if (this.courses && this.courses['Крипта']) {
                const btc2usdt = Object.values(this.courses['Крипта']).find(course => course.id == 37);
                return btc2usdt ? btc2usdt.rate : 0;
            }
            return 0;
        },

        //Покупка
        courses_USDT2kzt_rate_0() {
            if (this.courses && this.courses['Покупка']) {
                const usdt2kzt = Object.values(this.courses['Покупка']).find(course => course.id == 3);
                return usdt2kzt ? usdt2kzt.rate : 0;
            }
            return 0;
        },
        courses_USDT2uzs_rate_0() {
            if (this.courses && this.courses['Покупка']) {
                const usdt2uzs = Object.values(this.courses['Покупка']).find(course => course.id == 5);
                return usdt2uzs ? usdt2uzs.rate : 0;
            }
            return 0;
        },
        courses_USDT2rub_rate_0() {
            if (this.courses && this.courses['Покупка']) {
                const usdt2rub = Object.values(this.courses['Покупка']).find(course => course.id == 1);
                return usdt2rub ? usdt2rub.rate : 0;
            }
            return 0;
        },

        USDT2EUR_buy() {
            if (this.courses && this.courses['Покупка']) {
                const usdt2eur = Object.values(this.courses['Покупка']).find(course => course.title == 'USDT2EUR_buy');
                return usdt2eur ? usdt2eur.rate : 0;
            }
            return 0;
        },

        //Продажа
        courses_USDT2rub_rate_sale() {
            if (this.courses && this.courses['Продажа']) {
                const usdt2rub = Object.values(this.courses['Продажа']).find(course => course.title == 'USDT2RUB_sale');
                return usdt2rub ? usdt2rub.rate : 0;
            }
            return 0;
        },

        courses_USDT2kzt_rate_sale() {
            if (this.courses && this.courses['Продажа']) {
                const usdt2kzt = Object.values(this.courses['Продажа']).find(course => course.title == 'USDT2KZT_sale');
                return usdt2kzt ? usdt2kzt.rate : 0;
            }
            return 0;
        },

        courses_USDT2uzs_rate_sale() {
            if (this.courses && this.courses['Продажа']) {
                const usdt2uzs = Object.values(this.courses['Продажа']).find(course => course.title == 'USDT2UZS_sale');
                return usdt2uzs ? usdt2uzs.rate : 0;
            }
            return 0;
        },

        USDT2EUR_sale() {
            if (this.courses && this.courses['Продажа']) {
                const usdt2eur = Object.values(this.courses['Продажа']).find(course => course.title == 'USDT2EUR_sale');
                return usdt2eur ? usdt2eur.rate : 0;
            }
            return 0;
        },

        courses_BTC2USDT_updated_at() {
            if (this.courses && this.courses['Крипта']) {
                const btc2usdt = Object.values(this.courses['Крипта']).find(course => course.id === 37);
                return btc2usdt ? btc2usdt.updated_at : 0;
            }
            return 0;
        },
    },
}
</script>

<style scoped>
/*.open_course {
    position: absolute;
    background-color: #35634F;
    transition-duration: 0.8s;
    transition-property: display;
}

.open_block {
    cursor: pointer;
    height: 100%;
    transition-duration: 0.5s;
    transition-property: transform;
}    */

.fz-14 {
    font-size: 14px;
}
.fz-16 {
    font-size: 16px;
}
.green_border {
    border: 1px solid #35634F;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    transform: translateY(-5px);
}
.fw-300 {
    font-weight: 300;
}
.green_bg {
    background-color: #35634F;
}
.brs-5 {
    border-radius: 5px;
 }
.courses-block {
    padding-top: 45px;
    position: relative;
    z-index: 99;
}

.btn-primary {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    background: #4AA485;
    min-width: 268px;
    margin-left: auto;
    outline: 0;
    border: 0;
}

.btn-primary span{
    display: block;
}
.btn-primary:hover {
    background: #4AA485;
}

.btn-primary[aria-expanded = "true"] .arrow {
    transform: rotate(180deg);
}
.courses_title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.text-success {
    color: #74ED79 !important;
}

.text-danger {
    color: #FF6D6D !important;
}

@media(max-width:992px) {
    .btn-primary {
        width: 100%;    
    }
    .courses_wrap {
        gap: 10px;
    }
    .courses_wrap .courses_wrap_item:first-child {
        padding-top: 20px;
    }
    .courses_wrap .courses_wrap_item:nth-child(3) {
        padding-bottom: 10px;
    }
    .green_border {
        gap: 5px;
    }
}
</style>
