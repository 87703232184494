import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index/IndexComponent.vue'
import InvoicePayment from '@/components/InvoicePayment.vue'
import Account from '../views/AccountView.vue'
import PayLink from '../views/Pay-link.vue'
import Amlkyc from '../views/Aml-kyc.vue'
import Terms_of_use from '../views/Terms_of_use.vue'
import PrivacyPolice from '../views/Privacy-police.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    meta: { title: 'bsb money - покупка, продажа и обмен криптовалюты' }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { title: 'Личный кабинет - bsb money' }
  },
  {
    path: '/pay-link',
    name: 'pay-link',
    component: PayLink,
    meta: { title: 'Платёжная страница' }
  },
  {
    path: '/aml-kyc',
    name: 'aml-kyc',
    component: Amlkyc,
    meta: { title: 'Политика KYC / AML' }

  },
  {
    path: '/terms_of_use',
    name: 'terms_of_use',
    component: Terms_of_use,
    meta: { title: 'Пользовательское соглашение' }
  },
  {
    path: '/privacy-police',
    name: 'privacy-police',
    component: PrivacyPolice,
    meta: { title: 'Политика конфиденциальности' }
  },
  
  {
    path: '/invoice/:id1?',
    name: 'invoice',
    component: InvoicePayment,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to, from) => {
  // Используйте мета-тег title, если он есть, иначе установите заголовок по умолчанию
  document.title = to.meta && to.meta.title ? to.meta.title : 'bsb money - покупка, продажа и обмен криптовалюты';
})

export default router
