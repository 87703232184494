<template>
    <div class='lk__cart-item'>
        <div class="d-flex align-items-center gap-2">
            <img class='banks-logo' :src="'/img/banks/' + imgTitle + '.png'" />
            <span :style="data.active == 0 ? 'color: gray;': ''" class='me-3 u-font-size-16'>{{data.card_number | format}}</span>
        </div>
        <button class='card_delete' @click='card_delete(data.id)'><img width="14" height="14" src='/img/ico/ico-danger.svg' /></button>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {
        }
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, '$1 '),
    },
    computed: {
        imgTitle: function () { 
            if(this.data.sbp_bank) {
                return this.data.sbp_bank.title;
            } else {
                return this.data.name;
            }
        }
    },
    methods: {
        card_delete(id){
            eventBus.$emit('card_delete', id);
        },
    }
}
</script>


